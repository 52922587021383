/* eslint-disable no-debugger */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line require-jsdoc
// eslint-disable-next-line require-jsdoc
/* eslint-disable require-jsdoc */

export const getAppointmentVariant = (Appointment_Variant, country) => {
  const appointmentVariantV2 = process.env.REACT_APP_BOOKING_MODULE_URL
  const appointmentVariantV3 = process.env.REACT_APP_BOOKING_MODULE_URL_V3
  const appointmentVariantResult = (Appointment_Variant === 'version3.0' || Appointment_Variant === 'version3')
  && (country === 'Colombia' || country === 'colombia') ? appointmentVariantV3
    : appointmentVariantV2
  return {
    appointmentVariantResult
  }
}

export default getAppointmentVariant
