/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable max-params */
/**
 * sendRequest to middleware to get data
 * @param {Object} input request body
 * @param {Object} success success action
 * @param {Object} error error action
 * @param {Boolean} isOrthodontist is request for Orthodontist info
 * @returns {Object} send request and receive response
 */
const sendRequest = async (input, success, error, isOrthodontist = false) => {
  const BasicAuthUsername = process.env.REACT_APP_USER_API
  const BasicAuthPassword = process.env.REACT_APP_PASS_API
  const auth = `${BasicAuthUsername}:${BasicAuthPassword}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: authEncoded,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(input)
  }

  try {
    const url = process.env.REACT_APP_MIDDLEWARE_URL

    const response = await fetch(url, requestOptions)
    const data = await response.json()
    const diagnostic = isOrthodontist ? data.Orthodontist || {} : data.Patient || {}

    if (response.ok) {
      success && success(diagnostic)
    } else {
      error && error(data)
    }
  } catch (err) {
    error && error(err)
  }
}

/**
 * getProductPrice fetch product price for possible advanced
 * @param {Object} input request body
 * @returns {Number} the complex/moderate price
 */
const getProductPrice = async (input) => {
  const BasicAuthUsername = 'user'
  const BasicAuthPassword = 'secret'
  const auth = `${BasicAuthUsername}:${BasicAuthPassword}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: authEncoded,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(input)
  }

  try {
    const url = process.env.REACT_APP_MIDDLEWARE_URL

    const response = await fetch(url, requestOptions)
    const data = await response.json()
    const product = data.Product || {}

    if (response.ok) {
      return product
    }
    return data
  } catch (err) {
    return err
  }
}

export default { sendRequest, getProductPrice }
