/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useContext, useState, useEffect } from 'react'
import styles from './DoctorStatement.module.css'
import content from './DoctorStatement.content'
import { CustomerContext } from '../../context/context'
import * as constants from '../../Utils/constants'
import drPrado from '../../Assets/images/png/drprado3x.png'
import drBrenda from '../../Assets/images/png/female-dr.png'
import LoaderSection from '../LoaderSection/LoaderSection'
import Middleware from '../../Api/Middleware'

/**
 * Doctor Statement component
 * @returns {React.JSXElement} DoctorStatement component
 */
const DoctorStatement = ({ country }) => {
  const { customer } = useContext(CustomerContext)
  const { patientType, diagnosedBy, patientName } = customer
  const isColombia = country === 'Colombia' || country === 'colombia'

  const [error, setError] = useState(false)
  const [drInfo, setDrInfo] = useState({})

  useEffect(() => {
    const requestBody = {
      table: constants.ORTHO_CLASS,
      columns: {
        name: diagnosedBy,
        active: true
      }
    }
    Middleware.sendRequest(requestBody, setDrInfo, setError, true)
  }, [])

  if (error) {
    setDrInfo({
      photo: drPrado,
      gender: 'male',
      name: 'José Prado',
      imgClass: ' '
    })
    setError(false)
  }

  if (drInfo && !drInfo.photo) {
    if (drInfo.gender === 'female') {
      setDrInfo({ ...drInfo, photo: drBrenda })
    } else {
      setDrInfo({ ...drInfo, photo: drPrado })
    }
  }

  if (Object.keys(drInfo).length <= 0) {
    return (
      <div className={styles.LoaderContainer}>
        <LoaderSection />
      </div>
    )
  }

  let doctorMsg = null
  const primClasses = []
  const secondClasses = []
  switch (patientType) {
  case constants.MOONS:
  case constants.MOONS_ADVANCED:
  case constants.MOONS_POSSIBLE_ADVANCED:
  case constants.NEED_MEDICAL_TREATMENT:
  case constants.NEED_EXTRA_INFO:
  case constants.CANDIDATE:
  case constants.NO_CANDIDATE:
  case constants.DEFAULT_CASE:
    primClasses.push(styles.DrText, styles.PrimaryTextMoons)
    secondClasses.push(styles.DrText, styles.SecondaryTextMoons)
    doctorMsg = (
      <>
        <div className={styles.DoubleColumn}>
          <div className={primClasses.join(' ')}>
            {content[constants.MOONS].initial.replace('{dr_gender}', drInfo.gender === 'male' ? 'El doctor' : 'La doctora')
              .replace('{dr_name}', drInfo.name)}
            <strong style={{ fontWeight: '800' }}>
              {content[constants.MOONS].bold.replace('{name}', patientName)}
            </strong>
            {isColombia ? content.moonsCO.complement : content[constants.MOONS].complement}
          </div>
        </div>
        {(drInfo.name && !isColombia) && (
          <div className={styles.Row}>
            <div className={styles.Column}>
              <img
                src={drInfo.photo}
                alt="doctor"
                className={[styles.DrPrado, drInfo.imgClass || styles.BorderDrImg].join(' ')}
              />
            </div>
            <div className={styles.DoubleColumn}>
              <p className={styles.NameDoctor}>
                {`${content[constants.MOONS].doctor.title}${drInfo.name ? drInfo.name : ''}`}
              </p>
              <span className={styles.SubtitleDoctor}>
                {content[constants.MOONS].doctor.subtitle}
              </span>
            </div>
          </div>
        )}
      </>
    )
    break
  default: break
  }

  const mainContClasses = [styles.MainContainer]

  return (
    <div className={mainContClasses.join(' ')}>
      {doctorMsg}
    </div>
  )
}

export default DoctorStatement
