/**
 * @param {Object} promoSelected Promo selected object
 * @returns {Boolean} Banner is show
 */
const getBannerIsShow = (promoSelected) => {
  const isShow = !!promoSelected.bannerTitle
  return isShow
}

export default getBannerIsShow
