import { createClient } from 'contentful'
import {
  getCountry,
  getUtm
} from '../utils'

const isPreview = getUtm('preview')

/**
 * Retrieve entries with search parameters content type
 */
export default class ContentfulService {
  client = createClient({
    space: 'lslyn9ywzpwn',
    environment: process.env.REACT_APP_ENVIRONMENT,
    accessToken: isPreview
      ? process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN_PREVIEW
      : process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    host: isPreview && 'preview.contentful.com'
  })

  /**
   * @param {String} countryOps Pacient country
   * @returns {Array} Entries field from content type
   */
  getPromos = async (countryOps) => {
    const country = getCountry(countryOps)
    const entries = await this.client.getEntries({
      content_type: `candidate${country}Promo`,
      select: 'fields'
    })
    return entries
  }
}
