export const MOONS = 'moons'
export const MOONS_POSSIBLE_ADVANCED = 'moons posible advanced'
export const MOONS_ADVANCED = 'moons Advanced'
export const NO_CANDIDATE = 'No Candidate'
export const NEED_MEDICAL_TREATMENT = 'need_medical_treatment'
export const NEED_EXTRA_INFO = 'need_extra_info'
export const CANDIDATE = 'Candidate'
export const DEFAULT_CASE = 'DEFAULT'
export const MODALITY_HOME_KIT = 'Home Kit'
export const CURRENCY_MX = 'MXN'
export const CURRENCY_COL = 'COP'
export const CURRENCY_CL = 'CLP'
export const CURRENCY_PE = 'PEN'
export const WHATSAPP_COUNTRY_MX = '52 1 '
export const WHATSAPP_COUNTRY_CO = '57 '
export const WHATSAPP_COUNTRY_CL = '56 '
export const WHATSAPP_COUNTRY_PE = '51 '
export const WHATSAPP_MSG = 'https://api.whatsapp.com/send?phone={moonsNum}&text={message}'
export const WHATSAPP_URL = 'https://api.whatsapp.com/send?phone={moonsNum}'
export const FACEBOOK_MX = 'https://www.facebook.com/welovemoons'
export const FACEBOOK_CO = 'https://www.facebook.com/welovemoons.co'
export const FACEBOOK_CL = 'https://www.facebook.com/welovemoons.cl'
export const INSTAGRAM_MX = 'https://www.instagram.com/welovemoons'
export const INSTAGRAM_CO = 'https://www.instagram.com/welovemoons.co'
export const INSTAGRAM_CL = 'https://www.instagram.com/welovemoons.cl'
export const YOUTUBE_URL = 'https://www.youtube.com/welovemoons'
export const PATIENT_CLASS = 'Patient'
export const PRODUCT_CLASS = 'Product'
export const ORTHO_CLASS = 'Orthodontist'
export const PRADO_NAME = 'José Prado'
export const APPOINTMENT_SCHEDULED = 'Scheduled'
export const EXPERIMENT_ID = 'lFmGtsGbQjSRqTEpaJuCCQ'
export const ORIGINAL_NAME_VARIANT_EXPERIMENT = 'original'
export const promoBuenfin = false
export const FREE_APPOINMENT_VARIANT_MEDELLIN = 'freeMedellin'
export const FREE_APPOINMENT_VARIANT_BUCARAMANGA = 'freeBucaramanga'
export const PROMO_APPOINMENT_VARIANT_MEDELLIN = 'promoMedellin'
export const PROMO_APPOINMENT_VARIANT_BUCARAMANGA = 'promoBucaramanga'
