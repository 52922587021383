const apolloHeadingOptions = {
  moons: {
    msg: '¡Tu sonrisa deseada al alcance de tu mano!',
    description: 'Agenda tu cita y obtén la sonrisa de tus sueños'
  },
  possible_advanced: {
    msg: '¡{name}, eres precandidato!',
    description: 'Puedes tener la sonrisa de tus sueños con moons'
  },
  no_candidate: {
    msg: '¡Lo sentimos!',
    description: 'Nuestros doctores dicen que no eres candidato'
  },
  need_medical_treatment: {
    msg: '¡{name}! tu caso necesita algo más',
    description: 'Nuestros doctores dicen que necesitamos un pequeño paso más'
  },
  need_extra_info: {
    msg: '¡{name}! necesitamos más información',
    description: 'Nuestro equipo te contactará o puedes contactarnos ahora'
  },
  candidate: {
    msg: '¡{name}, está listo tu prediagnóstico!',
    description: 'Agenda tu cita y obtén la sonrisa de tus sueños'
  },
  default: {
    msg: '{name}, aún no tenemos tu prediagnóstico',
    description: 'Necesitamos más tiempo para determinar tu caso'
  }
}

export default apolloHeadingOptions
