export const freeAppointmentMessg = {
  freeAppointmentTitleMsg: 'Cita GRATIS, solo por 15min',
  freeAppointmentTextMsg: 'Durante tu cita, uno de nuestros especialistas realizará el escaneo 3D de tu sonrisa, el cual se utilizará para generar tu plan de tratamiento 100% personalizado.',
  freeAppointmentButtonMsg: 'Agendar cita gratis',
}

export const promoAppointmentMessg = {
  promoAppointmentTitleMsg: '¡Aprovecha la promo!',
  promoAppointmentTextMsg: 'Ahorra $70.000 y agenda tu cita para realizar el escaneo 3D de tu sonrisa, el cual se utilizará para generar tu plan de tratamiento 100% personalizado.',
  promoAppointmentButtonMsg: 'Agendar cita',
}
