/**
 * @param {String} param Query param to find
 * @returns {*} Query param if exist
 */
const getUtm = (param) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const queryParams = Object.fromEntries(urlSearchParams.entries())
  let utm = queryParams[param]
  if (queryParams[param] === 'true') {
    utm = true
  } else if (queryParams[param] === 'false') {
    utm = false
  }
  return utm
}

export default getUtm
