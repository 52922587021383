/**
 * @param {Object} promoSelected Promo selected object
 * @returns {Boolean} Sticker is show
 */
const getStickerIsShow = (promoSelected) => {
  const isShow = !!promoSelected.stickerImage
  return isShow
}

export default getStickerIsShow
