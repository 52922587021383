/* eslint-disable max-statements */
import React from 'react'
import style from './tablet.module.css'
import Slider from '../Slider/Slider'

/**
 * Tablet Component
 * @return {JSX.Element}.
 */
const Tablet = ({ info }) => (
  <div className={style.TabletContainer}>
    {info.map((item) => (
      <Slider
        key={item.id}
        item={item}
      />
    ))}
  </div>
)

export default Tablet
