import React, { useContext } from 'react'
import styles from './ApolloHeading.module.css'
import globalStyles from '../../index.module.css'
import * as constants from '../../Utils/constants'
import content from './ApolloHeading.content'
import { CustomerContext } from '../../context/context'

/**
 * ApolloHeading component
 * @returns {JSX.Element} ApolloHeading component
 */
const ApolloHeading = ({ country }) => {
  const { customer } = useContext(CustomerContext)
  const isPeru = country === 'Peru' || country === 'peru'
  const { patientType } = customer
  let displayedContent = null
  const msgClasses = [styles.Message, globalStyles.Column]
  const descClasses = [styles.Description, globalStyles.Column]
  switch (patientType) {
  case constants.MOONS:
  case constants.MOONS_POSSIBLE_ADVANCED:
  case constants.MOONS_ADVANCED:
  case constants.CANDIDATE:
  case constants.NEED_EXTRA_INFO:
  case constants.NEED_MEDICAL_TREATMENT:
  case constants.NO_CANDIDATE:
  case constants.DEFAULT_CASE:
    msgClasses.push(styles.MessageMoons)
    descClasses.push(styles.DescMoons)
    displayedContent = (
      <>
        <div className={msgClasses.join(' ')}>
          {isPeru ? '¡Tu mejor sonrisa al alcance de tu mano!' : content.moons.msg}
        </div>
      </>
    )
    break
  default: break
  }
  const msgContainerClasses = [styles.MsgContainer, globalStyles.DoubleColumn]
  return (
    <div className={styles.MainContainer}>
      <div className={msgContainerClasses.join(' ')}>
        {displayedContent}
      </div>
    </div>
  )
}

export default ApolloHeading
