export const contentDefault = {
  title: 'Cambia tu sonrisa en 6* meses',
  intro: 'Conoce las historias de nuestros pacientes que ya liberaron su sonrisa y ahora la comparten por todo el mundo'
}
export const contentCO = {
  title: 'Cambia tu sonrisa en 6* meses',
  intro: 'Conoce las historias de quienes ya liberaron su sonrisa y ahora la comparten por todo el mundo'
}
export const contentCL = {
  title: 'Cambia tu sonrisa a partir de 6 meses*',
  intro: 'Conoce las historias de nuestros pacientes que ya liberaron su sonrisa y ahora la comparten por todo el mundo.'
}
export const contentPE = {
  title: 'Cambia tu sonrisa a partir de 6 meses*',
  intro: 'Conoce las historias de nuestros pacientes que ya liberaron su sonrisa y ahora la comparten por todo el mundo.'
}
