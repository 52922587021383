import moment from 'moment'

/**
 * @param {Object} promoSelected Promo selected object
 * @returns {String} Date from init countdown
 */
const getBannerCounterDate = (promoSelected) => {
  let bannerCounterDate = ''
  if (promoSelected.bannerCounterTime) {
    bannerCounterDate = moment()
      .add(promoSelected.bannerCounterTime, 'minutes')
      .add(1, 'seconds').format()
  } else {
    bannerCounterDate = promoSelected.bannerCounterDate
  }
  return bannerCounterDate
}

export default getBannerCounterDate
