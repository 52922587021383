/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-child-element-spacing */
/* eslint-disable no-shadow */
/* eslint-disable no-undefined */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable require-jsdoc */
/* eslint-disable camelcase */
/* eslint-disable valid-jsdoc */
import React, { useContext, useState } from 'react'
import styles from './ScanPromoSection.module.css'
import { CustomerContext } from '../../context/context'
import { textDefault, textPE } from './ScanPromoSectionText'

/**
 * promoChile component
 * @returns {JSX.Element} promoChile component
 */

const ScanPromoSection = ({ country, appointmentVariantResult }) => {
  const { customer } = useContext(CustomerContext)
  const {
    paymentId
  } = customer

  const textBycountry = (country) => {
    let countryText = ''
    switch (country) {
    case 'Colombia':
    case 'colombia':
      countryText = textDefault
      break
    case 'Chile':
    case 'chile':
      countryText = textDefault
      break
    case 'Peru':
    case 'peru':
      countryText = textPE
      break
    default:
      countryText = textDefault
    }
    return countryText
  }

  const [textContent] = useState(textBycountry(country))

  const promoVersion = () => (
    <div className={styles.freeChileContainer}>
      <p className={styles.freeChileText}>
        {textContent.promoVersionText}
        {' '}

      </p>
      <p className={styles.freeChileTitle}>
        {textContent.promoVersiònPrice}
        {' '}
      </p>
    </div>
  )
  const redirectAppointment = (evt) => {
    evt.preventDefault()
    window.open(`${appointmentVariantResult.replace('[domain]', textContent.textCountry)}${paymentId}?utm_source=soy_candidato`)
  }
  return (
    <div className={styles.promoChileContainer}>
      <p className={styles.promoChileTitle}>{textContent.promoTitle}</p>
      <div className={styles.promoChileContent}>
        <p className={styles.promoChileSubtitle}>{textContent.promoSubtitle}</p>
        {promoVersion()}
        <button onClick={(evt) => redirectAppointment(evt)}>
          {textContent.buttonText}
        </button>
      </div>
      <div className={styles.promoChileText}>
        <p className={styles.promoChileTitleMobile}>{textContent.promoTitleMobile}</p>
        {textContent.promoText1 }
        <span>
          {' '}
          {textContent.countryText }
        </span>
        {textContent.promoText2 }
      </div>
    </div>
  )
}

export default ScanPromoSection
