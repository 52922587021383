const templateContent = {
  moons: {
    initial: 'Hola ',
    bold: '{name}',
    complement: ', revisé tu caso y trabajaremos juntos para lograr tu sonrisa con Moons. Para asegurarnos de que tu tratamiento sea completamente personalizado, necesitamos realizar un escaneo de tus dientes.',
    doctor: {
      title: 'Doc. ',
      lic: 'Ced. Licenciatura: 9936453',
      master: 'Ced. Maestría: 421526',
      subtitle: 'Especialista de sonrisa Moons.'
    }
  },
  moonsCO: {
    initial: 'Hola ',
    bold: '{name}',
    complement: ', revisé tu caso y trabajaremos juntos para lograr tu sonrisa con Moons. Para asegurarnos de que tu tratamiento sea completamente personalizado, necesitamos que un especialista aliado haga el escaneo de tus dientes.',
    doctor: {
      title: 'Doc. ',
      lic: 'Ced. Licenciatura: 9936453',
      master: 'Ced. Maestría: 421526',
      subtitle: 'Especialista de sonrisa Moons.'
    }
  },
  no_candidate: {
    initial: '{dr_gender} {dr_name} ha determinado que ',
    initial2: ' eres candidato al tratamiento moons',
    bold: 'NO',
    secondary: 'La complejidad de tu caso, no puede ser resuelta con nuestro tratamiento'
  },
  need_medical_treatment: {
    initial: '{dr_gender} {dr_name} ha determinado que para conocer tu diagnóstico, antes debemos realizar algunos procedimientos extra en tus dientes'
  },
  need_extra_info: {
    initial: '{dr_gender} {dr_name} ha determinado que necesitamos más información para diagnosticarte'
  },
  candidate: {
    initial: '{dr_gender} {dr_name} ha pre-diagnosticado que eres candidato al ',
    bold: 'tratamiento moons',
    secondary: 'Recuerda que para tener tu diagnóstico completo debes agendar una cita.'
  },
  default: {
    initial: 'Los doctores moons continúan trabajando tu caso',
    secondary: 'Pronto tendremos más información para ti'
  }
}

export default templateContent
