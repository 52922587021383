export const contentDefault = {
  title: '¡Adiós brackets, hola moons!',
  first: 'Tratamiento invisible y estético',
  second: '65% más económico que la competencia',
  third: '3 veces más rápido que brackets'
}

export const contentCL = {
  title: '¡Adiós frenillos, hola Moons!',
  first: 'Tratamiento invisible y estético',
  second: '50% más económico que la competencia*',
  third: '3 veces más rápido que frenillos*'
}

export const contentPE = {
  title: '¡Adiós brackets, hola Moons!',
  first: 'Tratamiento invisible y estético',
  second: '50% más económico que la competencia*',
  third: '3 veces más rápido que los brackets*'
}
