/* eslint-disable quotes */
/* eslint-disable no-debugger */
/* eslint-disable no-undefined */
/* eslint-disable radix */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable complexity */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable max-lines */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-max-depth */

import React,
{
  useState, useEffect, useCallback, useContext, useRef
} from 'react'
import { withRouter } from 'react-router-dom'
import ErrorPage from '@mymoons/error-page'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import { CustomerContext } from '../../context/context'
import styles from './Diagnostic.module.css'
import globalStyles from '../../index.module.css'
import content from './Diagnostic.content'
import * as constants from '../../Utils/constants'
import Middleware from '../../Api/Middleware'
import Header from '../Header/Header'
import ApolloHeading from '../ApolloHeading/ApolloHeading'
import ScanPromoSection from '../ScanPromoSection/ScanPromoSection'
import PromoAppointment from '../PromoAppointment/PromoAppointment'
import DoctorStatement from '../DoctorStatement/DoctorStatement'
import CallToAction from '../CallToAction/CallToAction'
import Footer from '../Footer/Footer'
import LoaderFullScreen from '../LoaderFullScreen/LoaderFullScreen'
import HiMoons from '../HiMoons/HiMoons'
import ScanMethods from '../ScanMethods/ScanMethods'
import BeforeAndAfter from '../BeforeAndAfter/BeforeAndAfter'
import IconWhats from '../../Assets/images/svg/icon-ionic-logo-whatsapp.svg'
import IconWhatsChile from '../../Assets/images/png/whatsChileIcon.png'
import SliderReviews from '../SliderReviews'
import data from '../../Utils/reviews'
import calculateTimeLeft from '../../Utils/experiments/caculateTimeLeft'
import { getAppointmentVariant } from '../../Utils/appointmentVariant'
import { paramsObject } from '../../Utils/createUrlParams'
import setCookieTimer from '../../Utils/setCookieTimer'
import PromoModal from '../PromoPopup/PromoPopup'
import stickerMx from '../../Assets/images/sticker/sticker-promo-mx.webp'
import stickerCo from '../../Assets/images/sticker/sticker-promo-co.webp'
import stickerCl from '../../Assets/images/sticker/sticker-promo-cl.webp'
import ContentfulContextProvider, {
  ContentfulContext
} from '../../Contentful/context'
import stickerPE from '../../Assets/images/sticker/stickerPeru.webp'

/**
 * isSafari Component
 * @returns {JSX.Element} Diagnostic Component
 */
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0
}

const mainVideo = 'https://d2h0mj07jmfux0.cloudfront.net/images/candidate-web/movimientos2.mp4'

const queryParams = paramsObject()
const { Journey_Variant, scenterPromo } = queryParams

setCookieTimer()

/**
 * Diagnostic Component
 * @param {Object} match router match
 * @returns {JSX.Element} Diagnostic Component
 */
// eslint-disable-next-line max-statements,require-jsdoc
const Diagnostic = ({ match }) => {
  const [error, setError] = useState(false)
  const [isCTABtnSticky, setisCTASticky] = useState(true)
  const [customerPublicKey, setCustomerPublicKey] = useState('')
  const { customer, changeCustomer } = useContext(CustomerContext)
  const [counterPromo, setCounterPromo] = useState(false)
  const {
    country,
    patientType,
    currency,
    paymentId,
    Appointment_Variant
  } = customer
  const appointmentVariant = getAppointmentVariant(Appointment_Variant, country)
  const { appointmentVariantResult } = appointmentVariant

  const {
    FREE_APPOINMENT_VARIANT_MEDELLIN,
    FREE_APPOINMENT_VARIANT_BUCARAMANGA,
    PROMO_APPOINMENT_VARIANT_MEDELLIN,
    PROMO_APPOINMENT_VARIANT_BUCARAMANGA
  } = constants
  const [timeLeft, setTimeLeft] = React.useState('')
  const [loadingCookie, setLoadingCookie] = React.useState(true)
  const [cookieTimerStatus, setCookieTimerStatus] = React.useState('')
  const isFreeAppoinment = Journey_Variant === FREE_APPOINMENT_VARIANT_MEDELLIN || Journey_Variant === FREE_APPOINMENT_VARIANT_BUCARAMANGA
  const promoOriginal = true

  const getCookie = async () => {
    const cookieTime = await Cookies.get('timer')
    return cookieTime
  }

  if (timeLeft === '') {
    getCookie()
      .then((result) => {
        const cookieTimer = parseInt(result)
        setCookieTimerStatus(cookieTimer)
        setTimeLeft(calculateTimeLeft(cookieTimer))
      })
  }

  React.useEffect(() => {
    if (isFreeAppoinment) {
      if (timeLeft.minutes === undefined && timeLeft.seconds === undefined) {
        setCounterPromo(false)
      } else {
        setCounterPromo(true)
      }
    }
    setLoadingCookie(false)
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(cookieTimerStatus))
    }, 1000)
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer)
  }, [timeLeft])

  const videoParentRef = useRef()
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0]

      // if the reference to video player has been obtained
      if (player) {
        /*
         * set the video attributes using javascript as per the
         * webkit Policy
         */
        player.controls = false
        player.playsinline = true
        player.muted = true
        player.setAttribute('muted', '')
        player.autoplay = true

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play()
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = 'none'
              })
          }
        }, 0)
      }
    }
  }, [])

  /**
   * @param {number} ms - milliseconds
   * @return {function} await for a time
   */
  const sleep = (ms) => new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

  /**
   * @param {String} publicKey - patient id
   * @param {number} counter - 0
   * @return {function} await for a time
   */
  const getPatient = async (publicKey, counter = 0) => {
    const requestBody = {
      table: constants.PATIENT_CLASS,
      columns: {
        PublicKey: publicKey
      }
    }
    setCustomerPublicKey(publicKey)
    await Middleware.sendRequest(requestBody, changeCustomer, async () => {
      if (counter > 8) {
        setError(true)
      } else {
        // eslint-disable-next-line no-plusplus,no-param-reassign
        ++counter
        sleep(counter * 500)
        await getPatient(publicKey, counter)
      }
    })
  }

  /**
   * Lifecycle DidMount useEffect
   * fetches Customer data by Public Key
   */
  useEffect(() => {
    const params = match && match.params ? match.params : null
    const publicKey = params ? params.id : ''
    getPatient(publicKey)
  }, [])

  /**
   * handleCTAContact
   * Handles contacting action for user
   * @param {String} country Customer's country
   * @param {String} patientType Customer's patient type
   * @returns {String} url to new tab to WhatsApp moons contact with pre-message
   */
  const handleCTAContact = useCallback(() => {
    let whatsAppUrl = constants.WHATSAPP_MSG
    switch (country) {
    case 'Colombia':
    case 'colombia':
      switch (patientType) {
      default:
        whatsAppUrl = whatsAppUrl
          .replace('{moonsNum}', encodeURIComponent(`${constants.WHATSAPP_COUNTRY_CO}${process.env.REACT_APP_WHATS_CO}`))
          .replace('{message}', encodeURIComponent(content.whatsapp))
        break
      }
      break
    case 'Chile':
    case 'chile':
      switch (patientType) {
      default:
        whatsAppUrl = whatsAppUrl
          .replace('{moonsNum}', encodeURIComponent(`${constants.WHATSAPP_COUNTRY_CL}${process.env.REACT_APP_WHATS_CL}`))
          .replace('{message}', encodeURIComponent(content.whatsapp))
        break
      }
      break
    case 'Peru':
    case 'peru':
      switch (patientType) {
      default:
        whatsAppUrl = whatsAppUrl
          .replace('{moonsNum}', encodeURIComponent(`${constants.WHATSAPP_COUNTRY_PE}${process.env.REACT_APP_WHATS_PE}`))
          .replace('{message}', encodeURIComponent(content.whatsapp))
        break
      }
      break

    default:
      switch (patientType) {
      default:
        whatsAppUrl = whatsAppUrl
          .replace('{moonsNum}', encodeURIComponent(`${constants.WHATSAPP_COUNTRY_MX}${process.env.REACT_APP_WHATS_MX}`))
          .replace('{message}', encodeURIComponent(content.whatsapp))
        break
      }
      break
    }
    return whatsAppUrl
  }, [
    country, patientType
  ])

  if (error) {
    return <ErrorPage />
  }

  if (Object.keys(customer).length <= 0 || loadingCookie) {
    return <LoaderFullScreen />
  }
  const actionBtnClasses = [
    constants.NO_CANDIDATE,
    constants.NEED_MEDICAL_TREATMENT,
    constants.NEED_EXTRA_INFO,
    constants.CANDIDATE
  ].includes(patientType)
    ? [styles.ActionBtnContact, styles.AnimationContainer]
    : [styles.ActionBtn, styles.AnimationContainer]
  const btnContainerClasses = [
    constants.NO_CANDIDATE,
    constants.NEED_MEDICAL_TREATMENT,
    constants.NEED_EXTRA_INFO,
    constants.CANDIDATE
  ].includes(patientType)
    ? [styles.BtnContainerContact]
    : [styles.BtnContainer]
  isCTABtnSticky && btnContainerClasses.push(styles.isSticky)

  let ctaUrl = ''
  const promoAppoinmentParams = `utm_source=soy_candidato&Journey_Variant=${Journey_Variant}&scenterPromo=${scenterPromo}`
  const isPromoOrFreeAppoiment = Journey_Variant
    === FREE_APPOINMENT_VARIANT_MEDELLIN
    || Journey_Variant === FREE_APPOINMENT_VARIANT_BUCARAMANGA
    || Journey_Variant === PROMO_APPOINMENT_VARIANT_MEDELLIN
    || Journey_Variant === PROMO_APPOINMENT_VARIANT_BUCARAMANGA
  if (
    !patientType
    || [
      constants.MOONS,
      constants.MOONS_POSSIBLE_ADVANCED,
      constants.MOONS_ADVANCED,
      constants.NEED_MEDICAL_TREATMENT,
      constants.NEED_EXTRA_INFO,
      constants.CANDIDATE,
      constants.NO_CANDIDATE,
      constants.DEFAULT_CASE,
    ].includes(patientType)
  ) {
    switch (currency) {
    case constants.CURRENCY_COL:
      ctaUrl = `${appointmentVariantResult.replace('[domain]', 'co')}${paymentId}?${promoAppoinmentParams}`
      break
    case constants.CURRENCY_CL:
      ctaUrl = `${appointmentVariantResult.replace('[domain]', 'cl')}${paymentId}?utm_source=soy_candidato`
      break
    case constants.CURRENCY_PE:
      ctaUrl = `${process.env.REACT_APP_BOOKING_MODULE_URL.replace('[domain]', 'pe')}${paymentId}?utm_source=soy_candidato`
      break
    default:
      ctaUrl = `${appointmentVariantResult.replace('[domain]', 'mx')}${paymentId}?utm_source=soy_candidato`
      break
    }
  }
  const redirectPromoAppointmentButton = (evt) => {
    evt.preventDefault()
    window.open(`${appointmentVariantResult.replace('[domain]', 'co')}${paymentId}?${promoAppoinmentParams}?utm_source=soy_candidato`)
  }

  const isColombia = country === 'Colombia' || country === 'colombia'
  const isChile = country === 'Chile' || country === 'chile'
  const isPeru = country === 'Peru' || country === 'peru'

  let sticker = stickerMx
  if (isColombia) {
    sticker = stickerCo
  } else if (isChile) {
    sticker = stickerCl
  } else if (isPeru) {
    sticker = stickerPE
  } else {
    sticker = stickerMx
  }
  const img = new Image()
  img.src = sticker

  const scanMethodsOrPromosSection = (countryToChooseSection) => {
    let countrySection = ''
    const scanMethodsDefaultSection = (
      <>
        <ScanMethods
          publicKey={customerPublicKey}
          country={country}
          appointmentVariantResult={appointmentVariantResult}
        />
        <div className={styles.whatsContainer}>
          <div className={styles.iconContainer}>
            <img
              className={styles.iconWhats}
              src={IconWhats}
              alt="whatsapp"
            />
          </div>
          <div className={styles.textWhatsContainer}>
            <p>{content.doubts}</p>
            <a
              id="preDiagnosticCTAWhatsappAccepted"
              href={handleCTAContact()}
              className={styles.OutlineButton}
            >
              {content.contactUs}
            </a>
          </div>
        </div>
        <BeforeAndAfter country={country} />
        <SliderReviews
          info={data}
          country={country}
        />
        <div className={styles.lastNote}>
          <p className={styles.textLastNote}>
            {isColombia ? content.disclaimerCO : content.disclaimer}
          </p>
        </div>
        <CallToAction handleIsSticky={setisCTASticky} />
      </>
    )
    switch (countryToChooseSection) {
    case 'Peru':
    case 'peru':
      countrySection = (
        <div>
          <ScanPromoSection country={country} appointmentVariantResult={appointmentVariantResult} />
          <div className={styles.whatsContainer}>
            <div className={styles.iconContainer}>
              <img
                className={styles.iconWhatsPE}
                src={IconWhats}
                alt="whatsapp"
              />
            </div>
            <div className={styles.textWhatsContainerPE}>
              <p>{content.doubts}</p>
              <a
                id="preDiagnosticCTAWhatsappAccepted"
                href={handleCTAContact()}
                className={styles.OutlineButtonPE}
              >
                {content.contactUs}
              </a>
            </div>
          </div>
          <BeforeAndAfter country={country} />
          <SliderReviews
            info={data}
            country={country}
          />
          <div className={styles.lastNote}>
            <p className={styles.textLastNotePE}>
              {content.disclaimerPE}
            </p>
          </div>
          <CallToAction handleIsSticky={setisCTASticky} />
        </div>
      )
      break
    case 'Chile':
    case 'chile':
      countrySection = (
        <div>
          <ScanPromoSection country={country} appointmentVariantResult={appointmentVariantResult} />
          <div className={isChile ? styles.whatsContainerChile : styles.whatsContainer}>
            <div className={isChile ? styles.iconContainerChile : styles.iconContainer}>
              <img
                className={isChile ? styles.iconWhatsChile : styles.iconWhats}
                src={IconWhatsChile}
                alt="whatsapp"
              />
              <p>{content.doubts}</p>
            </div>
            <div className={isChile ? styles.textWhatsContainerChile : styles.textWhatsContainer}>

              <a
                id="preDiagnosticCTAWhatsappAccepted"
                target="_blank"
                href={handleCTAContact()}
                className={isChile ? styles.OutlineButtonChile : styles.OutlineButton}
                rel="noreferrer"
              >
                Contáctanos
              </a>
            </div>
          </div>
          <BeforeAndAfter country={country} />
          <SliderReviews
            info={data}
            country={country}
          />
          <div className={styles.lastNoteCL}>
            <p className={styles.textLastNoteCL}>
              {content.disclaimerCL}
            </p>
          </div>
          <CallToAction handleIsSticky={setisCTASticky} />
        </div>
      )
      break
    case 'Colombia':
    case 'colombia':
      countrySection = isPromoOrFreeAppoiment ? countrySection = (
        <div>
          <PromoAppointment handleCounter={setCounterPromo} />

          <CallToAction handleIsSticky={setisCTASticky} />
        </div>
      )
        : scanMethodsDefaultSection
      break
    default:
      countrySection = scanMethodsDefaultSection
    }
    return countrySection
  }
  const StickerComponent = () => {
    const { promo } = useContext(ContentfulContext)
    if (isPromoOrFreeAppoiment || !promoOriginal) {
      return null
    }
    if (promo.sticker?.isShow) {
      return (
        <a
          href={ctaUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.MaxContainerCTA}
        >
          <img
            className={styles.sticker}
            src={promo.sticker.image.src}
            alt={promo.sticker.image.alt}
            width={promo.sticker.image.width}
            height={promo.sticker.image.height}
          />
        </a>
      )
    }
    return null
  }

  return (
    <ContentfulContextProvider country={country}>
      <div className={styles.PageContainer}>
        {country === 'Chile'
            && (
              <Helmet>
                <script async="" crossOrigin="anonymous" id="viddy-analytics-script-tag" data-viddy-analytics-type="ga" src="https://d2208bmkizb2br.cloudfront.net/viddy-analytics.js" />
                <script>
                  {`!function(e,n){var s=n.createElement("script");s.type="text/javascript",s.crossOrigin="anonymous",s.async=!0,s.src="https://d2208bmkizb2br.cloudfront.net/conversion/moons-lead-conversion.js";var t=n.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t)}(window,document);`}
                </script>
              </Helmet>
            )
        }
        <PromoModal
          counter={counterPromo}
          handleCounter={setCounterPromo}
          redirectPromoAppointmentButton={redirectPromoAppointmentButton}
        />
        <Header
          isColombia={isColombia}
          country={country}
          wpUrl={handleCTAContact()}
        />
        <div className={styles.Content}>
          <div className={styles.MaxContainer}>
            <div className={styles.ContainerVideo}>
              {/* eslint-disable-next-line react/jsx-max-depth */}
              <div
                ref={videoParentRef}
                dangerouslySetInnerHTML={{
                  __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            style="width: 100%;"
            preload="metadata"
          >
          <source  src="${mainVideo}" type="video/mp4" />
          </video>`
                }}
              />
            </div>
            <div className={styles.heroTextContainer}>
              <ApolloHeading country={country} />
              <DoctorStatement country={country} />
            </div>
          </div>
          <HiMoons country={country} />
          {scanMethodsOrPromosSection(country)}
          <div className={btnContainerClasses.join(' ')}>
            <div className={globalStyles.Column}>
              <StickerComponent />
              <a
                id="footerPayAppointmentPreDiagnostic"
                href={ctaUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={actionBtnClasses.join(' ')}
              >
                <div className={styles.BtnText}>
                  {(Journey_Variant
                    === FREE_APPOINMENT_VARIANT_MEDELLIN || Journey_Variant === FREE_APPOINMENT_VARIANT_BUCARAMANGA) && counterPromo
                    ? 'Agendar cita gratis'
                    : content.book
                  }
                </div>
              </a>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </ContentfulContextProvider>
  )
}

export default withRouter(Diagnostic)
