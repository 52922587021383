/* eslint-disable react/jsx-no-literals */
import React from 'react'
import styles from './Slider.module.css'

/**
 * Slider Component
 * @return {JSX.Element}.
 */
const Slider = ({ item }) => (
  <div className={[styles.Container, styles[item.type]].join(' ')}>
    <p>{item.text}</p>
    <img
      src={item.image}
      alt="warranty"
    />
  </div>
)

export default Slider
