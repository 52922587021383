const content = {
  title: 'Selecciona un método de escaneo',
  onSite: {
    title: 'Asesoría de sonrisa presencial',
    mx: {
      subtitleOne: 'Si te encuentras en ',
      bolder: 'CDMX, Monterrey, Guadalajara, Querétaro, León, Puebla o Cancún ',
      subtitleTwo: 'podrás asistir de manera presencial a tu asesoría de sonrisa después de haber realizado el pago y tu diagnóstico remoto.',
      price: '990',
      offer: '290',
    },
    co: {
      subtitleOne: 'Si te encuentras en ',
      bolder: 'Bogotá, Medellín, Bucaramanga, Ibagué y Barranquilla ',
      subtitleTwo: 'podrás asistir de manera presencial a tu asesoría de sonrisa después de haber realizado el pago y tu diagnóstico remoto.',
      price: '100.000',
      offer: '30.000',
    },
    textCTA: 'Agendar cita',
    devolution: 'Te reembolsaremos tu depósito después de tu cita si no terminas comprando el tratamiento.',
    color: '#d2ddff'
  },
  homeKit: {
    title: 'Kit de impresión en casa',
    subtitle: {
      subtitleOne: 'Si estás en cualquier otra ciudad ¡No te preocupes! podemos realizar todo ',
      bolder: 'el proceso de manera remota, siempre asistido por videollamada ',
      subtitleTwo: ' por nuestros ortodoncistas moons.',
    },
    subtitleCO: {
      subtitleOne: 'Si estás en cualquier otra ciudad ¡No te preocupes! podemos realizar todo ',
      bolder: 'el proceso de manera remota, siempre asistido por videollamada ',
      subtitleTwo: ' por alguien de nuestro equipo.',
    },
    mx: {
      price: '990',
      offer: '890',
    },
    co: {
      price: '200.000',
      offer: '159.900',
    },
    textCTA: 'Pagar Kit',
    devolution: 'Te reembolsaremos tu depósito después de tu videollamada si no terminas comprando el tratamiento.',
    color: '#eefcf6'
  }
}

export default content
