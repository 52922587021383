import React, {
  useState,
  useEffect,
  useContext,
  forwardRef
} from 'react'
import styles from './Footer.module.css'
import {
  FACEBOOK_MX,
  FACEBOOK_CO,
  WHATSAPP_COUNTRY_MX,
  WHATSAPP_COUNTRY_CO,
  WHATSAPP_COUNTRY_CL,
  WHATSAPP_COUNTRY_PE,
  INSTAGRAM_MX,
  INSTAGRAM_CO,
  YOUTUBE_URL,
  WHATSAPP_MSG
} from '../../Utils/constants'

import { CustomerContext } from '../../context/context'
import content from '../Diagnostic/Diagnostic.content'

/**
 * Footer component : includes reviews and contact links
 * @returns {JSX.Element} Footer component
 */
const Footer = () => {
  const { customer } = useContext(CustomerContext)
  const { country } = customer
  const [footerState, setFooterState] = useState({
    whatsapp: '',
    whatsappShow: '',
    facebook: '',
    instagram: '',
    youtube: ''
  })

  useEffect(() => {
    let newState = {}
    switch (country) {
    case 'Colombia':
    case 'colombia':
      newState = {
        whatsapp: encodeURIComponent(`${WHATSAPP_COUNTRY_CO}${process.env.REACT_APP_WHATS_CO}`),
        whatsappShow: process.env.REACT_APP_WHATS_CO,
        facebook: FACEBOOK_CO,
        instagram: INSTAGRAM_CO,
        youtube: YOUTUBE_URL
      }
      break
    case 'Chile':
    case 'chile':
      newState = {
        whatsapp: encodeURIComponent(`${WHATSAPP_COUNTRY_CL}${process.env.REACT_APP_WHATS_CL}`),
        whatsappShow: `+${WHATSAPP_COUNTRY_CL}${process.env.REACT_APP_WHATS_CL}`,
        facebook: FACEBOOK_CO,
        instagram: INSTAGRAM_CO,
        youtube: YOUTUBE_URL
      }
      break
    case 'Peru':
    case 'peru':
      newState = {
        whatsapp: encodeURIComponent(`${WHATSAPP_COUNTRY_PE}${process.env.REACT_APP_WHATS_PE}`),
        whatsappShow: `+${WHATSAPP_COUNTRY_PE}${process.env.REACT_APP_WHATS_PE}`,
        facebook: FACEBOOK_CO,
        instagram: INSTAGRAM_CO,
        youtube: YOUTUBE_URL
      }
      break

    default:
      newState = {
        whatsapp: encodeURIComponent(`${WHATSAPP_COUNTRY_MX}${process.env.REACT_APP_WHATS_MX}`),
        whatsappShow: process.env.REACT_APP_WHATS_MX,
        facebook: FACEBOOK_MX,
        instagram: INSTAGRAM_MX,
        youtube: YOUTUBE_URL
      }
      break
    }
    setFooterState(newState)
  }, [country])

  const contactClasses = [styles.Icons, styles.WhatsContainer]
  const whatsappClasses = [styles.Icons, country === 'Chile' || country === 'chile' ? styles.WhatsappIconChile : styles.WhatsappIcon]
  const footerWhatsUrl = WHATSAPP_MSG.replace('{moonsNum}', footerState.whatsapp)
    .replace('{message}', encodeURIComponent(content.whatsapp))
  return (
    <div className={styles.MainContainer}>
      <div className={styles.FooterContainer}>
        <div className={styles.Contact}>
          <a
            href={footerWhatsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={contactClasses.join(' ')}
          >
            <div
              className={whatsappClasses.join(' ')}
            />
            {footerState.whatsappShow}
          </a>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(Footer)
