import React, { useState, useEffect, useCallback } from 'react'
import styles from './CallToAction.module.css'
import globalStyles from '../../index.module.css'

/**
 * CallToAction component Button for booking appointment or contacting through whatsapp
 * @param {Function} handleIsSticky Hanlder to change is sticky state in parent
 * @returns {JSX.Element} CallToAction component
 */
const CallToAction = ({ handleIsSticky }) => {
  /**
   * Handle change for boolea flag for is sticky
   * @param {Boolean} flagIsSticky whether the interaction observer fires
   * @returns {Boolean} whether it is sticky
   */
  // eslint-disable-next-line no-unused-vars
  const [isSticky, setIsSticky] = useState(false)

  const changeSticky = useCallback((flagIsSticky) => {
    setIsSticky(flagIsSticky)
    handleIsSticky(flagIsSticky)
  }, [handleIsSticky, setIsSticky])

  const stickyRef = React.createRef()
  useEffect(() => {
    const cachedRef = stickyRef.current
    const observer = new IntersectionObserver(
      ([inter]) => changeSticky(inter.isIntersecting),
      { threshold: [0], rootMargin: '0px 0px -80px 0px' }
    )

    observer.observe(cachedRef)

    // unmount
    return () => {
      observer.unobserve(cachedRef)
    }
  }, [])

  return (
    <div className={styles.MainContainer}>
      <div className={styles.RowContainer}>
        <div className={globalStyles.Column}>
          <div
            className={styles.Title}
            ref={stickyRef}
          />
        </div>
      </div>
    </div>
  )
}

export default CallToAction
