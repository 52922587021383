/* eslint-disable */
/* eslint-disable require-jsdoc */
/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import styles from './Header.module.css'
import moonsLogo from '../../Assets/images/svg/logo.svg'
import WhatsappCta from '../whatsapp-cta/whatsapp-cta'
// import LocalBanner from '../NewBanner'
// import texts from './texts.json'
import * as constants from '../../Utils/constants'
import { paramsObject } from '../../Utils/createUrlParams'
import Banner from '../ContentfulBanner'
import { ContentfulContext } from '../../Contentful/context'
import moment from 'moment'
// const { title } = texts
const queryParams = paramsObject()
const { Journey_Variant } = queryParams

const toggleBannerPromo = (variant) => {
  const { promo } = useContext(ContentfulContext)
  let titleBanner = ''
  switch (variant) {
  case constants.FREE_APPOINMENT_VARIANT_MEDELLIN:
  case constants.FREE_APPOINMENT_VARIANT_BUCARAMANGA:
    titleBanner = 'Aprovecha nuestra promo y agenda tu cita ¡🔥 GRATIS 🔥!'
    break
  case constants.PROMO_APPOINMENT_VARIANT_MEDELLIN:
  case constants.PROMO_APPOINMENT_VARIANT_BUCARAMANGA:
    titleBanner = '¡Aprovecha nuestro descuento! Agenda tu cita por $30.000'
    break
  default:
    titleBanner = promo.banner.title
  }
  return titleBanner
}

const titleByCountry = (country) => {
  const { promo } = useContext(ContentfulContext)
  let countryTitle = ''
  switch (country) {
  case 'Colombia':
  case 'colombia':
    countryTitle = toggleBannerPromo(Journey_Variant)
    break
  default:
    countryTitle = promo.banner.title
  }
  return countryTitle
}

/**
 * Header component
 * @returns {JSX.Element} Header component
 */
const Header = ({ wpUrl, country }) => {
  const { promo } = useContext(ContentfulContext)
  const [timer, setTimer] = React.useState(0)
  const isFreeAppoinment = (Journey_Variant === constants.FREE_APPOINMENT_VARIANT_MEDELLIN)
    || (Journey_Variant === constants.FREE_APPOINMENT_VARIANT_BUCARAMANGA)
  const isPromoAppoinment = (Journey_Variant === constants.PROMO_APPOINMENT_VARIANT_MEDELLIN)
    || (Journey_Variant === constants.PROMO_APPOINMENT_VARIANT_BUCARAMANGA)

  React.useEffect(() => {
    const cookieTimer = parseInt(Cookies.get('timer'))
    setTimer(cookieTimer)
  }, [Cookies])
  return (
    <>
      {(!isFreeAppoinment || !isPromoAppoinment) && promo.banner?.isShow ? (
        <Banner options={{
          active: promo.banner.isShow,
          title: titleByCountry(country),
          images: promo.banner.images,
          logo: promo.banner.imageInTitle,
          date: {
            type: isFreeAppoinment ? 'ms' : promo.banner.counterType,
            datetime: isFreeAppoinment ? moment(timer).format() : promo.banner.counterDate
          }
        }} />
      ) : (
        <div />
      )}
      <header className={styles.HeaderContainer}>
        <div className={styles.wrapper}>
          <div className={styles.Logo}>
            <img
              src={moonsLogo}
              alt="moons logo"
            />
          </div>
          <WhatsappCta url={() => wpUrl} />
        </div>
      </header>
    </>
  )
}

export default Header
