/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

import('./banner.css')

/**
 * Format Date Time
 * @param {String} value Number
 * @returns {String} Format Date Time
 */
const formatDateTime = (value) => {
  const lengthValue = value.toString().length
  if (lengthValue === 1) {
    return `0${value}`
  }
  return value
}

/**
 * Calculate Time Left
 * @param {Object} date Date
 * @returns {String} Time Left
 */
const calculateTimeLeft = (date) => {
  const difference = Number(new Date(date)) - Number(new Date())
  let timeLeft = {}

  if (difference > 0) {
    timeLeft = {
      days: formatDateTime(Math.floor(difference / (1000 * 60 * 60 * 24))),
      hours: formatDateTime(Math.floor((difference / (1000 * 60 * 60)) % 24)),
      minutes: formatDateTime(Math.floor((difference / 1000 / 60) % 60)),
      seconds: formatDateTime(Math.floor((difference / 1000) % 60))
    }
  }

  return timeLeft
}

/**
 * Banner component
 * @author John Casas <john.casas@mymoons.co>
 * version 1.0.0
 * date 2022-02-15
 * @returns {JSX.Element} Banner component
 */
const Banner = ({ options }) => {
  const {
    title, active, logo, date, images, link
  } = options
  const { datetime, type } = date
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft(datetime))

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(datetime))
    }, 1000)

    return () => clearTimeout(timer)
  })

  if (!active) {
    return <div> </div>
  }

  /**
   * Render Images
   * @returns {html} Image HTML tag
   */
  const renderImages = () => {
    return images.map((img) => {
      return (
        <img key={img.alt} src={img.src} alt={img.alt} width={img.width} height={52} />
      )
    })
  }

  const renderCounter = () => {
    if (datetime) {
      const dh = (
        <div>
          <span>
            {timeLeft.days ? timeLeft.days : '00'}d
          </span>
          <span className='banner-counter-separator'>:</span>
          <span>{timeLeft.hours ? timeLeft.hours : '00'}h</span>
        </div>
      )

      const ms = (
        <div>
          <span>{timeLeft.minutes ? timeLeft.minutes : '00'}m</span>
          <span className='banner-counter-separator'>:</span>
          <span>{timeLeft.seconds ? timeLeft.seconds : '00'}s</span>
        </div>
      )

      const complete = (
        <div className='banner-counter'>
        {dh}
            <span className='banner-counter-separator'>:</span>
        {ms}
        </div>
      )

      return (
        <div>
          {type === 'dh' ? dh : type === 'ms' ? ms : complete}
        </div>
      )
    }
    return null
  }

  return (
    <div className={`banner ${link && 'banner-pointer'}`} onClick={() => link ? window.open(link) : {}}>
      {renderImages()}
      <div className='banner-content'>
        {logo.src && <img className='banner-logo' src={logo.src} alt={logo.alt} width={logo.width} height={logo.height} />}
        <span className='banner-title'>
          {title}
        </span>
        {renderCounter()}
      </div>
      {renderImages()}
    </div>
  )
}

Banner.propTypes = {
  options: PropTypes.object.isRequired,
}

export default Banner
