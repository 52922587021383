import getDevicePixelRatio from './getDevicePixelRatio'

/**
 * @param {Object} imageData Image data raw
 * @param {Number} width Image width
 * @param {Number} height Image height
 * @returns {Object} Empty if not exist or image params
 */
const getImage = (imageData, width, height) => {
  const devicePixelRatio = getDevicePixelRatio()
  let image = {}
  if (imageData) {
    image = {
      src: `${
        imageData.fields.file.url
      }?w=${
        devicePixelRatio * width
      }`,
      alt: imageData.fields.title,
      width,
      height
    }
  }
  return image
}

export default getImage
