/**
 * calculateTimeLeft
 * @param {string} cookieTimer cookie for calculate timer
 * @returns {JSX.Element} calculateTimer component
 */
const calculateTimeLeft = (cookieTimer) => {
  const difference = Number(new Date(cookieTimer)) - Number(new Date())
  let timeLeft = {}

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)).toString().split(''),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24).toString().split(''),
      minutes: Math.floor((difference / 1000 / 60) % 60).toString().split(''),
      seconds: Math.floor((difference / 1000) % 60).toString().split('')
    }
  }

  return timeLeft
}

export default calculateTimeLeft
