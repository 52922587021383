import getDevicePixelRatio from './getDevicePixelRatio'

/**
 * @param {Object} imagesData Images data raw
 * @param {Number} width Image width
 * @param {Number} height Image height
 * @returns {Array} Empty if not exist or images params
 */
const getImages = (imagesData) => {
  const devicePixelRatio = getDevicePixelRatio()
  let images = []
  if (imagesData) {
    images = imagesData.map((imageData) => {
      const width = 52
      const height = 52
      return {
        src: `${
          imageData.fields.file.url
        }?w=${
          devicePixelRatio * width
        }`,
        alt: imageData.fields.title,
        width,
        height
      }
    })
  }
  return images
}

export default getImages
