import React from 'react'
import styles from './whatsapp-cta.module.css'
import copies from './copies'
import icon from '../../Assets/images/svg/pink-wp.svg'

/**
 * WhatsappCta
 * @returns {JSX.Element} WhatsappCta component for header
 */
const WhatsappCta = ({ url }) => (
  <div className={styles.container}>
    <p className={styles.doubts}>{copies.doubts}</p>
    <a
      id="preDiagnosticCTAWhatsappAccepted"
      target="_blank"
      rel="noopener noreferrer"
      href={url()}
      className={styles.OutlineButton}
    >
      <div className={styles.textBtnWrapper}>
        {copies.contactUs}
        <span className={styles.wp}>{copies.wp}</span>
      </div>
      <img
        src={icon}
        alt="wp icon"
      />
    </a>
  </div>
)

export default WhatsappCta
