export const textDefault = {
  textCountry: 'cl',
  promoVersionText: '¡Queremos verte sonreír! Aprovecha nuestra promo y agenda tu cita.',
  promoVersiònPrice: '$10.900 CLP',
  promoTitle: 'Agenda tu cita de escaneo 3D',
  promoSubtitle: 'Escaneo 3D',
  buttonText: 'Agendar cita',
  promoTitleMobile: 'Agenda tu cita de escaneo 3D',
  promoText: 'Durante tu cita, uno de nuestros especialistas realizará el escaneo 3D de tu sonrisa, el cual se utilizará para generar tu plan de tratamiento. Además, obtendrás una previsualización de tu nueva sonrisa.'
}
export const textPE = {
  textCountry: 'pe',
  promoVersionText: '¡Queremos verte sonreír! Comienza hoy tu proceso y agenda tu cita.',
  promoVersiònPrice: 'S/ 39',
  promoTitle: 'Asesoría de sonrisa presencial',
  promoSubtitle: 'Escaneo 3D',
  buttonText: 'Agendar cita',
  promoTitleMobile: 'Asesoría de sonrisa presencial',
  promoText1: 'Si te encuentras en ',
  countryText: 'Lima ',
  promoText2: 'podrás asistir de manera presencial a tu asesoría de sonrisa después de haber realizado el pago y tu diagnóstico remoto.',
}
