import React from 'react'
import styles from './MethodCard.module.css'
import content from './MethodCard.content'

/**
 * HiMoons component UI to show the great properties of moons.
 * @returns {JSX.Element} A title with 3 sentences. Each one with images.
 */
const MethodCard = ({
  color,
  image,
  title,
  subtitle,
  price,
  offer,
  textCTA,
  action,
  fillButton,
  colombia,
  id,
  devolutionCopy
}) => {
  /**
   * priceFormat.
   * @param {string} number - price to convert
   * @returns {string} price formatted
   */
  const priceFormat = number => (colombia ? `$${number} COP` : `$${number} MXN`)
  return (
    <div className={styles.Container}>
      <div
        className={styles.ImageWrapper}
        style={{ backgroundColor: color }}
      >
        <img
          className={styles.Image}
          src={image}
          alt="hero card"
        />
      </div>
      <div className={styles.TextWrapper}>
        <p className={styles.Title}>
          {title}
        </p>
        <p className={styles.SubTitle}>
          {subtitle.subtitleOne}
          <span className={styles.SubTitleBolder}>{subtitle.bolder}</span>
          {subtitle.subtitleTwo}
        </p>
        <div className={styles.PriceWrapper}>
          <p className={styles.OfferText}>
            {content.textOffer}
          </p>
          <div className={styles.offerWrapper}>
            <span className={styles.PriceOffer}>{priceFormat(offer)}</span>
            <span className={styles.NewPrice}>
              {priceFormat(price)}
            </span>
          </div>
        </div>
        <div className={styles.withOutWorriesWrapper}>
          <p className={styles.withOutWorries}>
            <span>Empieza hoy sin compromiso. </span>
            {devolutionCopy}
          </p>
        </div>
        <a
          id={id}
          href={action}
          rel="noopener noreferrer"
          target="_blank"
          className={fillButton ? styles.Button : styles.OutlineButton}
        >
          {textCTA}
        </a>
      </div>
    </div>
  )
}

export default MethodCard
