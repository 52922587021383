/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable camelcase */
import React, { useState } from 'react'
import { paramsObject } from '../../Utils/createUrlParams'
import { freeAppointmentMessg, promoAppointmentMessg } from './PromoPopup.messages'
import officeMedicalImage from '../../Assets/images/png/medicalOfficeImage.png'
import * as constants from '../../Utils/constants'
import styles from './PromoPopup.module.css'

const {
  modalVeilContainer,
  promoModalContainer,
  promoImgContainer,
  promoModalTitle,
  promoModalText,
  skipButton
} = styles

const {
  FREE_APPOINMENT_VARIANT_MEDELLIN,
  FREE_APPOINMENT_VARIANT_BUCARAMANGA,
  PROMO_APPOINMENT_VARIANT_BUCARAMANGA,
  PROMO_APPOINMENT_VARIANT_MEDELLIN
} = constants

const queryParams = paramsObject()
const { Journey_Variant } = queryParams
const isFreeAppoinment = Journey_Variant === FREE_APPOINMENT_VARIANT_MEDELLIN || Journey_Variant === FREE_APPOINMENT_VARIANT_BUCARAMANGA
const isPromoAppoinment = Journey_Variant === PROMO_APPOINMENT_VARIANT_MEDELLIN || Journey_Variant === PROMO_APPOINMENT_VARIANT_BUCARAMANGA

const PromoModal = ({ counter, redirectPromoAppointmentButton }) => {
  const [showmodal, setShowModal] = useState(true)

  const closeModal = (evt) => {
    evt.preventDefault()
    setShowModal(false)
  }

  return (
    (isFreeAppoinment || isPromoAppoinment) && showmodal ? (
      <div className={modalVeilContainer}>
        <div className={promoModalContainer}>
          <figure className={promoImgContainer}>
            <img
              src={officeMedicalImage}
              alt="medical-office"
            />
          </figure>
          <p className={promoModalTitle}>{isFreeAppoinment && counter ? freeAppointmentMessg.freeAppointmentTitleMsg : promoAppointmentMessg.promoAppointmentTitleMsg}</p>
          <p className={promoModalText}>
            {isFreeAppoinment && counter ? freeAppointmentMessg.freeAppointmentTextMsg : promoAppointmentMessg.promoAppointmentTextMsg}
          </p>
          <button
            onClick={
              (evt) => redirectPromoAppointmentButton(evt)
            }
          >
            {isFreeAppoinment && counter ? freeAppointmentMessg.freeAppointmentButtonMsg : promoAppointmentMessg.promoAppointmentButtonMsg}
          </button>
          <p
            className={skipButton}
            onClick={(evt) => closeModal(evt)}
          >
            Omitir por ahora
          </p>
        </div>
      </div>
    )
      : null
  )
}

export default PromoModal
