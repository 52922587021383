/* eslint-disable require-jsdoc */
import React, { useState } from 'react'
import styles from './BeforeAndAfter.module.css'
import {
  contentDefault, contentPE, contentCL, contentCO
} from './BeforeAndAfter.content'
import ba1 from '../../Assets/images/png/b&a-woman1.png'
import ba2 from '../../Assets/images/png/b&a-man.png'
import ba3 from '../../Assets/images/png/b&a-woman2.png'
import baWomanDeskFirstBelow from '../../Assets/images/png/b&a-woman-desk-first-below.png'
import baWomanDeskLastAbove from '../../Assets/images/png/b&a-woman-desk-last-above.png'
import baWomanDeskLastBelow from '../../Assets/images/png/b&a-woman-desk-last-below.png'

const titlesByCountry = (country) => {
  let countryTitles = ''
  switch (country) {
  case 'Peru':
  case 'peru':
    countryTitles = contentPE
    break
  case 'Chile':
  case 'chile':
    countryTitles = contentCL
    break
  case 'Colombia':
  case 'colombia':
    countryTitles = contentCO
    break
  default:
    countryTitles = contentDefault
  }
  return countryTitles
}

/**
 * HiMoons component UI to show the great properties of moons.
 * @returns {JSX.Element} A title with 3 sentences. Each one with images.
 */
const BeforeAndAfter = ({ country }) => {
  const [content] = useState(titlesByCountry(country))
  const isChile = country === 'Chile' || country === 'chile'
  const isPeru = country === 'Peru' || country === 'peru'
  const styleChileandPeru = !!(isChile || isPeru)

  return (
    <div className={styleChileandPeru ? styles.containerChile : styles.container}>
      <div className={styleChileandPeru ? styles.marginChile : styles.margin}>
        <p className={styleChileandPeru ? styles.titleChile : styles.title}>{content.title}</p>
        <p className={styleChileandPeru ? styles.introCL : styles.intro}>{content.intro}</p>
        <div className={styles.imageWrapper}>
          <img
            className={styles.deskImage}
            src={baWomanDeskFirstBelow}
            alt="baWomanDeskFirstBelow"
          />
          <img
            className={styles.image}
            src={ba3}
            alt="before and after 3"
          />
          <img
            className={styles.deskImage}
            src={baWomanDeskLastBelow}
            alt="baWomanDeskLastBelow"
          />
          <img
            className={styles.imageOne}
            src={ba1}
            alt="before and after 1"
          />
          <img
            className={styles.image}
            src={ba2}
            alt="before and after 2"
          />
          <img
            className={styles.deskImage}
            src={baWomanDeskLastAbove}
            alt="baWomanDeskLastAbove"
          />
        </div>
      </div>
    </div>
  )
}
export default BeforeAndAfter
