/* eslint-disable require-jsdoc */
import Cookies from 'js-cookie'
import extractTopDomain from './extractTopDomain'

const setCookieTimer = () => {
  const { domain } = document
  const topDomain = extractTopDomain(domain)
  if (!Cookies.get('timer')) {
    const timer = new Date(Number(new Date()) + 9e5).getTime()
    if (topDomain) {
      return Cookies.set('timer', timer, {
        expires: 365, domain: topDomain, secure: true, sameSite: 'none'
      })
    }
    return Cookies.set('timer', timer, { expires: 365 })
  }
  return 'ya hay timer'
}

export default setCookieTimer
