/**
 * @param {String} countryOps Patient country
 * @returns {String} Country code in pascal case
 */
const getCountry = (countryOps) => {
  let country = ''
  switch (countryOps) {
  case 'México':
    country = 'Mx'
    break
  case 'Colombia':
    country = 'Co'
    break
  case 'Chile':
    country = 'Cl'
    break
  case 'Peru':
    country = 'Pe'
    break
  default:
    country = ''
    break
  }
  return country
}

export default getCountry
